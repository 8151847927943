/* eslint-disable @next/next/no-img-element */

import Link from 'next/link';
import classNames from 'classnames';
import { fromUnixTime, Locale, isFuture, addHours } from 'date-fns';
import { useRouter } from 'next/router';
import { MatchListItemFragment, TeamListItemFragment } from '@amf/shared/types/graphql';
import { formatDate, getDateLocale } from '@amf/shared/utils/date';
import { useTranslate } from '@tolgee/react';
import { ReactNode } from 'react';

interface MatchListItemTeamProps {
  team?: TeamListItemFragment;
  type: 'home' | 'visiting';
}

function MatchListItemTeam({ type, team }: MatchListItemTeamProps) {
  if (!team) {
    return (
      <div
        className={classNames(
          'MatchListItem__team',
          'MatchListItem__team--empty',
          `MatchListItem__team--${type}`,
        )}
      >
        <div className='MatchListItem__teamBrand' />
        <h5 className='MatchListItem__teamName'>-</h5>
      </div>
    );
  }

  return (
    <div className={classNames('MatchListItem__team', `MatchListItem__team--${type}`)}>
      {team.club.brandUrl ? (
        <img className='MatchListItem__teamBrand' src={team.club.brandUrl} alt={team.club.name} />
      ) : (
        <div className='MatchListItem__teamBrand' />
      )}
      <h5 className='MatchListItem__teamName'>{team.name}</h5>
    </div>
  );
}

interface MatchListItemTimeProps {
  date: Date;
  locale: Locale;
}

function MatchListItemTime({ date, locale }: MatchListItemTimeProps) {
  return (
    <div className='MatchListItem__time'>
      <span>{formatDate(date, 'EEEEEE HH:mm', { locale })}</span>
      <small>{formatDate(date, 'dd/MM/yy', { locale })}</small>
    </div>
  );
}

interface MatchListItemScoreProps {
  match: MatchListItemFragment;
  date: Date;
  locale: Locale;
  live: boolean;
}

function MatchListItemScore({ match, date, locale, live }: MatchListItemScoreProps) {
  const { t } = useTranslate();
  return (
    <div className='MatchListItem__score'>
      <span>{match.score}</span>
      {live ? (
        <small>{t('match.list.now')}</small>
      ) : (
        <small>{formatDate(date, 'dd/MM/yy HH:mm', { locale })}</small>
      )}
    </div>
  );
}

interface MatchListItemProps {
  match: MatchListItemFragment;
  prefix: string;
  round?: ReactNode;
  info?: ReactNode;
  disableAssists?: boolean;
  disablePoints?: boolean;
}

function MatchListItem({
  match,
  prefix,
  round,
  info,
  disableAssists,
  disablePoints,
}: MatchListItemProps) {
  const { t } = useTranslate();
  const { locale } = useRouter();
  const dateLocale = getDateLocale(locale);
  const start = fromUnixTime(match.dateStart);
  const end = match.dateEnd ? fromUnixTime(match.dateEnd) : null;
  const live = end === null && match.live && match.started;

  const dateLocaleForIsFuture = isWMF() ? addHours(start, -getOffset('Europe/Prague') / 60) : start;
  const future = isFuture(dateLocaleForIsFuture);

  return (
    <Link href={`${prefix}/${match.id}`}>
      <a
        className={classNames('MatchListItem', {
          'MatchListItem--live': live,
        })}
      >
        <div className='MatchListItem__main'>
          {round && <div className='MatchListItem__round'>{round}</div>}
          {match.ground && (
            <div className='MatchListItem__ground'>
              <i className='MatchListItem__groundIcon icons-stadium' />
              <div className='MatchListItem__groundContent' title={t('match.list.ground')}>
                <span>{match.ground?.name ?? t('match.list.ground.empty')}</span>
              </div>
            </div>
          )}
        </div>
        <MatchListItemTeam team={match.home?.team} type='home' />
        {future && !live && <MatchListItemTime date={start} locale={dateLocale} />}
        {(!future || live) && (
          <MatchListItemScore match={match} date={start} locale={dateLocale} live={live} />
        )}
        <MatchListItemTeam team={match.visiting?.team} type='visiting' />
        <div className='MatchListItem__info'>
          {match.liveStreamEnabled && (
            <span className='MatchListItem__infoItem MatchListItem__infoItem--red'>
              <i className='bi bi-tv-fill' />
              {t('match.list.liveStream')}
            </span>
          )}
          {match.news.length > 0 && (
            <span className='MatchListItem__infoItem'>
              <i className='bi bi-newspaper' />
              {t('match.list.news')}
            </span>
          )}
          {match.subtitle && <span className='MatchListItem__infoItem'>{match.subtitle}</span>}
          {info}
        </div>
        <i className='MatchListItem__chevron bi bi-chevron-right' />
      </a>
    </Link>
  );
}

export default MatchListItem;

export function isWMF() {
  return process.env.NEXT_PUBLIC_UNION === '8c29f3dc-a9a4-47ae-850b-988553ee8621';
}

export const getOffset = (timeZone = 'UTC', date = new Date()) => {
  const utcDate = new Date(date.toLocaleString('en-US', { timeZone: 'UTC' }));
  const tzDate = new Date(date.toLocaleString('en-US', { timeZone }));
  return (tzDate.getTime() - utcDate.getTime()) / 6e4;
};
